<template>
    <div class="d-flex flex-wrap">
        <div :key="element.id" v-for="element in listData"
             class="mb-2 d-flex align-items-start mr-2 card p-1 pr-3">
            <div class="flex-fill" v-if="element.content">
                <div class="text-nowrap">
                    <span class="label pb-0 pt-0 mr-2 d-inline-block text-center"
                          v-bind:class="{'bg-gradient-navy': element.action === 1, 'bg-gradient-orange': element.action !== 1 }"
                          style="width: 2rem;">
                        <i v-if="element.action === 1" class="el-icon-top"></i>
                        <i v-else class="el-icon-bottom"></i>
                    </span>
                    <strong>{{element.content[0].name || element.content[0].Name}}</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import baseComponent from "@/scripts/baseComponent";
    import userTransferingApi from "@/api/common/userTransferingApi";
    export default {
        extends: baseComponent,
        props: {
            userId: {
                type: String,
                default: null,
            }
        },
        data() {
            return {
                listData: [],
                isLoading: false,
            };
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                this.isLoading = true;
                if (this.userId) {
                    userTransferingApi.getUserTransferingDetail(this.userId).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                if (res.data.data) {
                                    res.data.data.forEach(x => {
                                        try {
                                            x.contentRp = x.content.replaceAll('\\', '\\\\');
                                            x.content = JSON.parse(x.content);
                                        } catch (error) {
                                            //console.error(error);
                                            try {
                                                x.content = JSON.parse(x.content.replaceAll('\\', '\\\\'));
                                            } catch (error1) {
                                                console.error(error1);
                                            }
                                        }
                                    });
                                }
                                this.$set(this, 'listData', res.data.data);
                            }
                        } catch (error) {
                            console.log("getUserTransferingDetail -> error", error);
                        }
                        this.isLoading = false;
                    }).catch(error => {
                        console.log("getUserTransferingDetail -> error", error);
                        this.isLoading = false;
                    });
                }
            },
        }
    }
</script>